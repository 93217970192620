import * as Handlebars from "handlebars";
import dateFormat from "../lib/dateformat/dateformat";
import { appendHtml } from "./dom";
import { capitalize, encodeHtml, groupBy, isDateInThisWeek, parseDate, sort } from "./util";
import { getCookie } from "./cookie";

let cacheDate = window.tumarket.cacheDate;
window.Handlebars = Handlebars;

let aliases = [
];

function chunk(array, size) {
    let result = [];
    for (let i = 0; i < array.length / size; i++) {
        result.push(array.slice(i * size, (i+1) * size));
    }

    return result;
}

let loadedTemplates = [];
let loadingTemplates = [];

/** @type {Object.<string, string[]>} */
let templateDependencies = {};

/** @type {Object.<string, Handlebars.HelperDelegate>} */
let helpers = {
    equals: (a,b) => a == b,
    or: function() { return [...arguments].slice(0, -1).some(e => e) },
    and: function() { return [...arguments].slice(0, -1).every(e => e) },
    coalesce: function() { return [...arguments].slice(0, -1).find(e => e) },
    not: a => !a,
    isNull: a => a == null,
    len: a => (a || '').length,
    gt: (a,b) => (a || 0) > (b || 0),
    lt: (a,b) => (a || 0) < (b || 0),
    slice: (a,b,c) => (a || []).slice((b || 0), (c || 0)),
    skip: (a,b) => (a || []).slice((b || 0)),
    truncate: (val, len) => {
        if (!val) return '';
        return val.length > len ? val.substring(0, len) + '…' : val;
    },
    truncateMiddle: (val, len) => {
        if (!val) return '';
        return val.substring(0, len) + '…' + val.substring(val.length - len, val.length);
    },
    lowercase: a => a.toLowerCase(),
    toJson: a => JSON.stringify(a || {}),
    toFormattedJson: a => JSON.stringify(a, null, 2),
    getDate: () => new Date(),
    parseDate: a => parseDate(a),
    formatDate: (a,b) => a ? dateFormat(new Date(a), b) : '',
    formatNumber: a => new Intl.NumberFormat('ru-RU', { minimumFractionDigits: a % 1 ? 2 : 0, maximumFractionDigits: 2 }).format(a),
    formatNumber2: a => new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a),
    formatNumber3: a => a?.toFixed(3).replace(/\.?0+$/, '').replace('.', ','),
    formatKop: a => new Intl.NumberFormat('ru-RU', { minimumIntegerDigits: 2 }).format(a),
    formatPrice: a => {
        var price = a.toFixed(2).replace(',', '.');
        var arr = price.split('.');
        if (arr.length > 1)
        {
            let res = `<span data-price="${price}"><b>${new Intl.NumberFormat('ru-RU').format(Number(arr[0]))}<sup>${new Intl.NumberFormat('ru-RU', { minimumIntegerDigits: 2 }).format(arr[1])}</sup></b></span>`;
            return res;
        }
        else
        {
            let res = `<span data-price="${price}"><b>${new Intl.NumberFormat('ru-RU').format(Number(arr[0]))}</b></span>`;
            return res;
        }
    },
    add: (a,b) => Number(a || 0) + Number(b || 0),
    contains: (a,b) => (a || '').toString().toLowerCase().indexOf((b || '').toString().toLowerCase()) > -1,
    startsWith: (a,b) => (a || '').toString().toLowerCase().startsWith((b || '').toString().toLowerCase()),
    urlEncode: a => encodeURIComponent(a),
    urlEscape: a => a?.replace("%", "%25").replace(" ", "%20"),
    urlDecode: a => { 
        let res = '';
        try {
            res = decodeURIComponent(a);
        } catch {}
        return res;
    },
    parseJson: a => { 
        let res = {};
        try {
            res = JSON.parse(a);
        } catch {}
        return res;
    },
    printif: (cond, a) => {
        if (cond) return a;
        return "";
    },
    iif: (cond, a, b) => {
        if (cond) return a;
        return b;
    },
    in: (item, array) => array.includes(item),
    split: (string, separator) => string?.split(separator),
    join: (array, separator) => array?.join(separator),
    substring: (string, index, length) => string?.substring(index, index + length),
    get: (array, index) => {
        if (index >= array.length) return null;
        return array[index];
    },
    getValue: (object, key) => object[key]?.toString(),
    getObject: (object, key) => object[key],
    replace: (string, pattern, replacement) => string?.replace(pattern, replacement),
    replaceRegex: (string, pattern, replacement) => string?.replace(new RegExp(pattern), replacement),
    testRegex: (string, pattern) => new RegExp(pattern).test(string),
    whereEquals: (array, field, value) => array?.filter(e => e[field] == value) || [],
    whereNotEquals: (array, field, value) => array?.filter(e => e[field] != value) || [],
    mapField: function(array, field) {
        if (!array?.length) return [];

        let args = [...arguments].slice(0, -1);
        let helper = args[2];
        let mapper;
        if (!helper) {
            mapper = e => e;
        } else {
            mapper = e => helpers[helper](...[e, ...args.slice(3)])
        }

        return array.map(e => mapper(e[field]));
    },
    map: function(array, helper) { 
        return array?.map(e => helpers[helper](...[e, ...[...arguments].slice(0, -1).slice(2)]));
    },
    /**
     * 
     * @param {object[]} array 
     * @returns 
     */
    flatMap: function(array) {
        if (!array?.length) return [];

        let args = [...arguments].slice(0, -1);
        let field = null;
        if (args.length == 2) field = args[1];

        if (field) array = array.filter(e => e[field]).map(e => e[field]);

        return array.flatMap(e => e);
    },
    getRandom: (array) => array[Math.floor(Math.random() * array.length)],
    random: (min, max) => min + Math.floor(Math.random() * (max - min + 1)),
    concat: function() { return [...arguments].slice(0, -1).join('') },
    nconcat: function() { 
        let args = [...arguments].slice(0, -1);
        if (args.some(e => !e)) return '';
        return args.join('');
     },
    array: function() { return [...arguments].slice(0, -1) },
    filter: function(a) {
        let args = [...arguments].slice(0, -1);
        let field = args[1];

        if (field) {
            return a.filter(e => e[field]);
        }

        return a.filter(e => e)
    },
    capitalize: a => capitalize(a),
    htmlAttributes: options => {
        let attributes = Object.entries(options.hash).filter(e => e[1]);
        if (!attributes.length) return '';

        return attributes.map(e => `${e[0]}="${encodeHtml(e[1].toString())}"`).join(' ');
    },
    htmlStyle: options => {
        let styles = Object.entries(options.hash).filter(e => e[1]);
        if (!styles.length) return '';
        
        return `style="${styles.map(e => `${e[0]}: ${e[1]}`).join('; ')}"`;
    },
    getCookie: name => getCookie(name),
    case: function() {
        let args = [...arguments].slice(0, -1);
        let defaultValue = args.slice(-1)[0];
        let rest = args.slice(0, -1);
        for (let [condition, value] of chunk(rest, 2)) {
            if (condition) return value;
        }
        return defaultValue;
    },
    switch: function() {
        let args = [...arguments].slice(0, -1);
        let value = args[0];
        let defaultResult = args.slice(-1)[0];
        let rest = args.slice(1, -1);
        for (let [option, result] of chunk(rest, 2)) {
            if (value == option) return result;
        }
        return defaultResult;
    },
    groupBy: function(array, field) {
        let args = [...arguments].slice(0, -1);

        if (args.length > 2) {
            let helper = args[2];
            let mapper = e => helpers[helper](...[e, ...args.slice(3)]);
            array = array.map(mapper);
        }

        return groupBy(array, field);
    },
    min: array => sort(array)[0] || null,
    max: array => sort(array, true)[0] || null,
    sort: function(array, desc) {
        let args = [...arguments].slice(0, -1);
        let helper = args[2];
        let mapper;
        if (!helper) {
            mapper = e => e;
        } else {
            mapper = e => helpers[helper](...[e, ...args.slice(3)])
        }

        return sort(array, desc, mapper);
    },
    sortBy: function(array, field, desc) {
        let args = [...arguments].slice(0, -1);
        let helper = args[3];
        let mapper;
        if (!helper) {
            mapper = e => e;
        } else {
            mapper = e => helpers[helper](...[e, ...args.slice(4)])
        }

        let copy = [...array];
        copy.sort((a, b) => {
            if (mapper(a[field]) > mapper(b[field])) return 1;
            if (mapper(a[field]) < mapper(b[field])) return -1;
            return 0;
        });

        if (desc) copy.reverse();
        return copy;
    },
    first: array => {
        if (!array?.length) return null;
        return array[0];
    },
    last: array => {
        if (!array?.length) return null;
        return array.slice(-1)[0];
    },
    dateToTimestamp: a => new Date(a).getTime(),
    timestampToDate: a => new Date(a),
    isToday: function(a) {
        let args = [...arguments].slice(0, -1);

        let offset = 0;
        if (args.length == 2) offset = args[1];

        let today = new Date();
        today = new Date(today.setDate(today.getDate() + offset));
        let day = new Date(a);
        

        return today.setHours(0,0,0,0) === day.setHours(0,0,0,0);
    },
    isThisWeek: function(a) {
        let args = [...arguments].slice(0, -1);

        let offset = 0;
        if (args.length == 2) offset = args[1];

        let day = new Date(a);
        return isDateInThisWeek(day, offset);
    },
    isThisMonth: function(a) {
        let args = [...arguments].slice(0, -1);

        let offset = 0;
        if (args.length == 2) offset = args[1];

        let day = new Date(a);
        let today = new Date();
        return day.getFullYear() == today.getFullYear() && day.getMonth() == today.getMonth() + offset;
    },
    toString: a => a?.toString(),
    entries: a => {
        if (!a) return null;
        return Object.entries(a).map(e => ({ key: e[0], value: e[1] }));
    },
    isArray: a => {
        return Array.isArray(a);
    },
    isObject: a => {
        return typeof a === 'object';
    },
    multiply: (a, b) => a * b,
    divide: (a, b) => a / b,
    floor: a => Math.floor(a),
    round: a => Math.round(a),
    mod: (a, b) => a % b,
    object: options => options.hash,
    apply: (helper, args_array) => helpers[helper](...args_array, null)
};

/**
 * 
 * @param {string} url 
 * @param {boolean} [fetchDependencies=true]
 * @returns {Promise<void>}
 */
function loadTemplate(url, fetchDependencies = true) {
    if (loadedTemplates.indexOf(url) > -1) {
        return Promise.resolve();
    }
    
    let dependencies = [];
    let nameMatch = url.match(/\/template\/([a-zA-Z0-9_-]+)\/?(wrap)?/);
    let name = nameMatch ? nameMatch[1] : null;

    return fetch(url, { headers: { "tuajax": "true" } }).then(response => {
        dependencies = response.headers.get('template-dependencies')?.split(',').map(e => e.trim());
        return response.text();
    }).then(template => {
        if (document.querySelector(`#template-${name}`)) return Promise.resolve();
        appendHtml("body", template);
        loadedTemplates.push(url);
        return Promise.resolve();
    }).then(() => {
        if (fetchDependencies && dependencies?.length) {
            templateDependencies[name] = dependencies;

            return Promise.all(dependencies.map(e => { 
                return loadTemplate(`/template/${e}/wrap?v=${cacheDate}`, false);
            })).then(() => Promise.resolve());
        }
        return Promise.resolve();
    });
}

/**
 * 
 * @param {string} name 
 * @param {boolean} [fetchDependencies=true] 
 * @param {boolean} [partial=false] 
 * @returns {Promise<void>}
 */
function loadPrecompiledTemplate(name, fetchDependencies = true, partial = false) {
    let originalName = name;
    let alias = aliases.find(e => e.at(0) == name)?.at(1);
    name = alias || name;

    let cacheKey = `${name}${partial ? "_partial" : ""}`;
    if (loadedTemplates.includes(cacheKey)) {
        return Promise.resolve();
    }

    if (loadingTemplates.includes(cacheKey)) {
        return new Promise(resolve => {
            document.addEventListener('tu.template.loaded', e => {
                if (e.detail.template == cacheKey) {
                    resolve()
                }
            });
        });
    }
    
    loadingTemplates.push(cacheKey);

    let dependencies = [];
    let url = `/template/${name}/precompiled?v=${cacheDate}${partial ? "&partial=true" : ""}`;

    return fetch(url, { headers: { "tuajax": "true" } }).then(response => {
        dependencies = response.headers.get('template-dependencies')?.split(',').map(e => e.trim()).filter(e => e);
        return response.text();
    }).then(template => {
        if (document.getElementById(`precompiled-${name}`)) return Promise.resolve();
        let script = document.createElement('script');
        script.id = `precompiled-${name}`;
        script.innerHTML = template;
        document.body.appendChild(script);

        //partial alias
        Handlebars.partials[name] = Handlebars.templates[name]; 
        Handlebars.partials[`template-${name}`] = Handlebars.templates[name];

        if (alias) {
            Handlebars.templates[originalName] = Handlebars.templates[name];
            Handlebars.partials[originalName] = Handlebars.templates[name]; 
            Handlebars.partials[`template-${originalName}`] = Handlebars.templates[name];
        }

        return Promise.resolve();
    }).then(() => {
        if (fetchDependencies && dependencies?.length) {
            templateDependencies[name] = dependencies;

            return Promise.all(dependencies.map(e => { 
                return loadPrecompiledTemplate(e, true);
            }))
            .then(() => {
                loadingTemplates = loadingTemplates.filter(e => e != cacheKey);
                loadedTemplates.push(cacheKey);
                document.dispatchEvent(new CustomEvent('tu.template.loaded', { detail: { template: cacheKey } }));
                return Promise.resolve();
            });
        }

        loadingTemplates = loadingTemplates.filter(e => e != cacheKey);
        loadedTemplates.push(cacheKey);
        document.dispatchEvent(new CustomEvent('tu.template.loaded', { detail: { template: cacheKey } }));
        return Promise.resolve();
    });
}

/**
 * 
 * @param {string[]} urls 
 * @returns 
 */
function loadTemplates(urls) {
    return Promise.all(urls.map(e => loadTemplate(e + "?v=" + cacheDate)));
}

/**
 * 
 * @param {string[]} names 
 * @returns 
 */
function loadPrecompiledTemplates(names) {
    return Promise.all(names.map(e => loadPrecompiledTemplate(e)));
}

function initHelpers() {
    for (let helper in helpers) {
        Handlebars.registerHelper(helper, helpers[helper]);
    }
}

function initUaParserHelper(){
    return import('ua-parser-js').then(modules => {
        Handlebars.registerHelper('uaparser', (a, b) => {
            let data = modules.default(a);
            if (b == 'os') return data.os.name;
            if (b == 'browser') return data.browser.name;
            if (b == 'device') return data.device.model;
            if (b == 'type') return data.device.type;
            return '';
        });
    });
}

/**
 * 
 * @param {string} template 
 * @returns 
 */
function getPrecompiledTemplate(template){
    return Handlebars.templates[template];
}

initHelpers();

export { loadPrecompiledTemplates, getPrecompiledTemplate, loadTemplates, helpers, initUaParserHelper };