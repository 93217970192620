﻿import * as signalR from "@microsoft/signalr";
import { showDialog } from "./global/dialog";
import { getCookie, setCookie } from "./global/cookie";
import { addEventDelegate, toggleClass } from "./global/dom";

var notifications = {
	options: {
		hubName: "signalRHub"
	},
	hubInitialized: false,
	hub: null,
	init: function (options) {
		Object.assign(this.options, options);

		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/js/sw.js');
		}

		addEventDelegate(document, 'click', ".tum-enableNotifications", () => {
			this.toggleNotifications(null, true);
		});

		return this.initHub().then(() => {
			this.askForPermission(false, true);
		})
	},
	registerCallback: function(name, callback){
		if (this.hubInitialized) { 
			this.hub.on(name, callback);
			return;
		}
		
		window.addEventListener('signalRReady', () => this.hub.on(name, callback));
	},
	initHub: function () {
		let self = this;
		this.hub = new signalR.HubConnectionBuilder().withUrl("/" + this.options.hubName).build();

		if (!this.hub) {
			console.log("notification hub not found");
			return Promise.resolve();
		}

		this.registerCallback('showNotification', this.showNotification);

		function startHubCallback() {
			self.hubInitialized = true;
			window.dispatchEvent(new Event('signalRReady'));

			try {
				self.hub.invoke("connectNotifications");
			} catch {
				console.log("Failed to connect to push notifications");
			}
		}

		this.hub.onclose(function () {
			self.hubInitialized = false;
			setTimeout(startHubCallback, 5000);
		});

		return this.hub.start().then(startHubCallback);
	},
	showNotification: function (title, body, id) {
		if (!("Notification" in window)) {
			console.log("This browser does not support desktop notification");
			return;
		}

		if (!('serviceWorker' in navigator)) {
			return;
		}

		if (document.querySelector(".tum-enableNotifications .fa-toggle-off")) {
			return;
		}

		if (Notification.permission == "granted") {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				registrations[0].showNotification(title, { body: body, data: { id: id }, icon: "/favicon.ico" });
			});
		}
		else if (Notification.permission != "denied") {
			Notification.requestPermission().then(function (permission) {
				if (permission == "granted") {
					navigator.serviceWorker.getRegistrations().then(function (registrations) {
                        registrations[0].showNotification(title, { body: body, data: { id: id }, icon: "/favicon.ico" });
					});
				}
			});
		}
	},
	askForPermission: function (showDisabledWarning, readCookie) {
		let self = this;
		if (!("Notification" in window)) {
			console.log("This browser does not support desktop notification");
			return;
		}

		if (Notification.permission != "granted" && Notification.permission != "denied") {
			Notification.requestPermission().then(function (permission) {
				if (permission == "granted") {
					self.toggleNotifications("enable");
				}
				else {
					self.toggleNotifications("disable");
				}
			});
		}
		else if (Notification.permission == "granted" && readCookie) {
			var notificationsEnabled = getCookie("tum-enableNotifications") == "1" ? "enable" : "disable";
			self.toggleNotifications(notificationsEnabled);
		}

		if (Notification.permission != "granted" && showDisabledWarning) {
			var instr = "<a href='/info/push-anable'>Как разрешить пуш-уведомления от TU.Market</a>";
			showDialog({
				title: "Уведомления запрещены веб-браузером",
				content: "Чтобы включить push-уведомления, нужно разрешить их в вашем веб-браузере<br>" + instr,
				onShown: () => toggleClass("#asModal .btn-primary", "d-none", true)
			});
		}
	},
	toggleNotifications: function (code, ask) {
		if (code == "enable") {
			toggleClass(".tum-enableNotifications i", "fa-toggle-on", true);
			toggleClass(".tum-enableNotifications i", "fa-toggle-off", false);
		}
		else if (code == "disable") {
			toggleClass(".tum-enableNotifications i", "fa-toggle-on", false);
			toggleClass(".tum-enableNotifications i", "fa-toggle-off", true);
		}
		else {
			toggleClass(".tum-enableNotifications i", "fa-toggle-off fa-toggle-on");
		}

		if (document.querySelector(".tum-enableNotifications .fa-toggle-on")) {
			if (ask) {
				this.askForPermission(true, false);
			}

			setCookie("tum-enableNotifications", "1", 30);
		}
		else {
			setCookie("tum-enableNotifications", "0", 30);
		}
	}
};

export default notifications;